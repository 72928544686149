.sgtForm #sgtSubmitDiv{
	padding: 0.5em 0 0.5em 0;
}

.sgtForm #sgtSubmitDiv input,.sgtForm #sgtSubmitDiv span{
	vertical-align: middle;
}

.sgtForm #sgtSubmitDiv span {
	display:block;
	padding: 0.3em 0 0.3em 3em;
}

.sgtForm .input25{
	max-width: 14.5em;
}

.sgtForm .input50{
	max-width: 27em;
}

.sgtForm .input8{
	max-width: 5.5em;
}

.sgtForm div{
	border: none;
	border-style: none;
	padding: 0.3em 0 0 0;
}

.sgtForm input{
	width: 90%;
}

.sgtForm input,.sgtForm select,.sgtForm textarea{
	font-family: inherit;
	font-size: inherit;
	vertical-align: top;
}

.sgtForm input[type=checkbox]{
	width: 1em;
}

.sgtForm input[type=radio]{
	width: 1em;
}

.sgtForm input[type=submit]{
	white-space: nowrap;
	width: 11em;
}

.sgtForm label{
	display: inline-block;
	padding: 0.1em;
	text-align: left;
	vertical-align: top;
	width: 100%;
}
.sgtForm textarea {
	width: 90%;
}
.sgtForm fieldset {
	
}
