// Palette
$white: white;
$black: #333333;
$blue: #5775b1;
$red: #d44117;
$lightGrey: #F1F0EC;
$brown: #666666;

//$green: #bcb876;
// $green: #bfb874;
$green: green;

$primaryBg: $white;
$sectionBg: $white;

$primaryTXT: $black;
$sectionTXT: $black;

$linkHover: $red;
$alertColor: $red;

$linkCol: $green;
$navBarBg:  $green;
$navItemHover: $blue;
$navItemDropdownTXT: $blue;

$navBarTop: $brown;
$navItemDivider: $brown;
$navItemTXT: $brown;
$navSelectedBg: $brown;

$asideBorder: $red;
$asideTXT: $black;
$asideHover: $green;
$asideBg: $white;

$navItemBg: $lightGrey;
$navSelectedTXT: $lightGrey;
$articleBorder: $lightGrey;
$sectionBorder: $lightGrey;
$footerBg: $lightGrey;

body {
	background-color: $primaryBg;
	color: $primaryTXT;
}

a {
	background-color: $primaryBg;
	color: $linkCol;
}

a:hover {
	background-color: $primaryBg;
	color: $linkHover;
}

aside {
	border-color: $asideBorder;
	background-color: $asideBg;
	color: $asideTXT;
}

header .navRow{
	background-color: $navBarBg;
	border-top-color: $navBarTop;
}

nav .dropDownButton span{
	background-color: $navItemBg;
	color: $navItemDropdownTXT;
}

nav li {
	 border-right-color: $navItemDivider;
}

nav li a{
	background-color: $navItemBg;
	color: $navItemTXT;
	text-decoration: none;
}

nav li a:hover {
	background-color: $navItemBg;
	color: $navItemHover;
	text-decoration: underline;
}

nav li a.active {
    background-color: $navSelectedBg;
		color: $navSelectedTXT;
}

article{
	border-color: $articleBorder;
}

section {
	border-color: $sectionBorder;
}

section .legend {
	background-color: $sectionBg;
	color: $sectionTXT;
}

aside {
	border-color: $asideBorder;
	background-color: $asideBg;
	color: $asideTXT;
}

aside a {
	background-color: $asideBg;
	color: $asideTXT;
}

aside a:hover {
	background-color: $asideBg;
	color: $asideHover;
}

footer{
	background-color: $footerBg;
}
footer a{
	background-color: $footerBg;
	color: $primaryTXT;
}
.alert {
	color: $alertColor;
}
